<template>
  <div class="mt-4">
    <div class="vx-row">
      <div class="vx-col w-full">
        <ValidationErrors :errors="errors"/>
      </div>
    </div>
    <div class="vx-row mb-3" v-if="!idRekanan">
      <div class="vx-col w-full">
        <div class="con-vs-alert con-vs-alert-warning con-icon">
          <div class="vs-alert con-icon">
            <i class="vs-icon notranslate icon-scale icon-alert feather icon-info null"></i>
            Pilih Rekanan terlebih dahulu pada tab PO Header.
          </div>
        </div>
      </div>
    </div>

    <div class="vx-row mb-3">
      <div class="vx-col sm:w-1/2 w-full">
        <div class="flex flex-wrap justify-between items-center mb-1">
          <div class="mb-4 md:mb-0 mr-4">
            <div class="flex">
              <vs-button color="success" icon-pack="feather" icon="icon-plus" @click="addRow" class="py-2 px-3 mr-2" :disabled="!idRekanan">Add</vs-button>
              <vs-button color="danger" icon-pack="feather" icon="icon-x" :disabled="items.length < 1" @click="clearRows" class="py-2 px-3">Clear</vs-button>
            </div>
          </div>
        </div>
      </div>
      <div class="vx-col sm:w-1/2 w-full px-0">
        <vs-input icon-pack="feather" icon="icon-search" class="mb-4 md:mb-0 mr-4 float-right" placeholder="Cari" v-model="search"/>
      </div>
    </div>
    <vs-table :data="rowsItems" stripe class="fix-table-input-background">
      <template slot="thead">
        <vs-th class="whitespace-no-wrap">#</vs-th>
        <vs-th class="whitespace-no-wrap">Action</vs-th>
        <vs-th class="whitespace-no-wrap">No. PR</vs-th>
        <vs-th class="whitespace-no-wrap">Nama Barang/Jasa</vs-th>
        <vs-th class="whitespace-no-wrap">Satuan</vs-th>
        <vs-th class="whitespace-no-wrap">Qty</vs-th>
        <vs-th class="whitespace-no-wrap">Harga</vs-th>
        <vs-th class="whitespace-no-wrap">Total</vs-th>
        <vs-th class="whitespace-no-wrap">File</vs-th>
      </template>
      <template slot-scope="{data}">
        <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
          <vs-td>{{ index + 1 }}</vs-td>
          <vs-td>
            <vs-button color="danger" class="py-2 px-3" type="filled" icon-pack="feather" icon="icon-x" size="small" @click="removeRow(item.uuid)"/>
          </vs-td>
          <vs-td>
            <vs-input class="w-48" v-model="item.no_pengadaan" disabled/>
          </vs-td>
          <vs-td>
            <vx-input-group class="w-48">
              <vs-input :value="item.nama_item_pengadaan" @click="showItemsPoChooser(item.uuid)" readonly/>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button type="filled" icon-pack="feather" icon="icon-search" @click="showItemsPoChooser(item.uuid)"/>
                </div>
              </template>
            </vx-input-group>
          </vs-td>
          <vs-td>
            <vs-input class="w-full" v-model="item.satuan_item_pengadaan" disabled/>
          </vs-td>
          <vs-td>
            <vs-input class="w-20" v-model="item.qty_actual" disabled/>
          </vs-td>
          <vs-td>
            <v-money class="w-full" v-model="item.harga_satuan"/>
          </vs-td>
          <vs-td>
            <v-money class="w-48" :value="item.harga_satuan * item.qty_actual" disabled/>
          </vs-td>
          <vs-td>
            <a :href="item.file_penawaran_url" target="_blank" v-if="item.file_penawaran_url">
              <vs-chip color="primary"><vs-avatar icon-pack="feather" icon="icon-file"/><span>File</span></vs-chip>
            </a>
            <p v-else>-</p>
          </vs-td>
        </vs-tr>
        <vs-tr v-if="data.length > 0">
          <vs-td class="whitespace-no-wrap"></vs-td>
          <vs-td class="whitespace-no-wrap"></vs-td>
          <vs-td class="whitespace-no-wrap"></vs-td>
          <vs-td class="whitespace-no-wrap"></vs-td>
          <vs-td class="whitespace-no-wrap"></vs-td>
          <vs-td class="whitespace-no-wrap"></vs-td>
          <vs-td class="whitespace-no-wrap">TOTAL NILAI PO</vs-td>
          <vs-td class="whitespace-no-wrap"><v-money class="w-full" :value="totalNilaiPo" disabled/></vs-td>
          <vs-td class="whitespace-no-wrap"></vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <div class="vx-row float-right mt-5">
      <div class="vx-col w-full flex">
        <vs-button @click="prev" type="border" class="mr-3" icon-pack="feather" icon="icon-arrow-left">Prev</vs-button>
        <vs-button @click="next" icon-pack="feather" icon="icon-arrow-right" icon-after>Next</vs-button>
      </div>
    </div>

    <!--modals-->
    <vs-popup class="sm:popup-w-70 popup-content-no-padding animate-none"
              title="Pilih Item PR"
              :active.sync="modalItemsPoChooser.active">
      <ItemsPoChooser :selectable="true"
                    :externalFilter="filterItemsPo"
                    @selected="onSelectedModalPoChooser"/>
    </vs-popup>
  </div>
</template>

<script>
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import VMoney from '@/views/components/v-money/VMoney'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'

export default {
  name: 'TabBarang',
  props: {
    initData: { default: null, type: Array } // for edit
  },
  components: {
    ItemsPoChooser: () => import('@/views/pages/proyek/po/parts/ItemsPoChooser'),
    ValidationErrors,
    VMoney
  },
  computed: {
    errors () {
      return this.$store.state.proyek.poAdd.errors.tabBarang.messages
    },
    tabHeader () {
      return this.$store.state.proyek.poAdd.tabHeader
    },
    idProyek () {
      return this.tabHeader.id_proyek
    },
    idRekanan () {
      return this.tabHeader.id_rekanan
    },
    filterItemsPo () {
      if (!this.idProyek || !this.idRekanan) return null
      return {
        id_proyek: this.tabHeader.id_proyek,
        id_rekanan: this.tabHeader.id_rekanan
      }
    },
    totalNilaiPo () {
      return _.sumBy(this.items, item => item.qty_actual * item.harga_satuan)
    },
    rowsItems () {
      const search = this.search
      const items = this.items
      if (search != null) {
        return _.filter(items, (item) => {
          return item.nama_item_pengadaan.toLowerCase().includes(search.toLowerCase())
        })
      }
      return items
    }
  },
  watch: {
    initData (newVal, oldVal) {
      if (newVal && !_.isEqual(newVal, oldVal)) {
        this.items = _.cloneDeep(newVal)
      }
    },
    'tabHeader.id_rekanan' (newVal, oldVal) {
      if (oldVal) {
        this.clearRows()
      }
    },
    items: {
      deep: true,
      handler (value) {
        this.commitDataToStore(value)
      }
    }
  },
  data () {
    return {
      search: null,
      modalItemsPoChooser: {
        rowUuid: null,
        active: false
      },
      items: [],
      deletedIds: []
    }
  },
  methods: {
    showItemsPoChooser (rowUuid) {
      this.modalItemsPoChooser.rowUuid = rowUuid
      this.modalItemsPoChooser.active = true
    },

    onSelectedModalPoChooser (data) {
      // validate for same item
      if (_.find(this.items, item => item.id_pengadaan_d === data.id_pengadaan_d) !== undefined) {
        return this.notifyWarning('Item yang sama tidak dapat ditambahkan lagi.')
      }

      const uuid = this.modalItemsPoChooser.rowUuid
      const index = _.findIndex(this.items, item => item.uuid === uuid)
      this.items[index].id_pengadaan = data.id_pengadaan
      this.items[index].id_pengadaan_d = data.id_pengadaan_d
      this.items[index].id_item_pengadaan = data.id_item_pengadaan
      this.items[index].no_pengadaan = data.no_pengadaan
      this.items[index].nama_item_pengadaan = data.nama_item_pengadaan
      this.items[index].satuan_item_pengadaan = data.satuan_item_pengadaan
      this.items[index].qty_actual = data.qty_actual
      this.items[index].harga_satuan = data.harga
      this.items[index].file_penawaran_url = data.file_penawaran_url
      this.modalItemsPoChooser.active = false
    },

    addRow () {
      const row = {
        uuid: uuid(),
        id_pengadaan: null,
        id_pengadaan_d: null,
        id_item_pengadaan: null,
        no_pengadaan: null,
        nama_item_pengadaan: null,
        satuan_item_pengadaan: null,
        qty_actual: null,
        harga_satuan: null
      }
      this.search = null
      this.items.unshift(row)
    },

    removeRow (uuid) {
      const index = _.findIndex(this.items, item => item.uuid === uuid)
      const item = this.items[index]
      if (item.id) {
        this.deletedIds.push(item.id)
      }
      this.items.splice(index, 1)
    },

    clearRows () {
      const deletedIds = _.map(_.filter(this.items, item => item.id), it => it.id)
      this.deletedIds = [...this.deletedIds, ...deletedIds]
      this.items = []
    },

    commitDataToStore: _.debounce(function (value) {
      const payload = _.cloneDeep({
        items: value,
        totalNilaiPo: this.totalNilaiPo
      })
      this.$store.commit('proyek/poAdd/SET_TAB_BARANG', payload)
    }, 500),

    idr (value, decimal = 2) {
      return this.$options.filters.idr(value, decimal)
    },

    prev () {
      this.$store.commit('proyek/poAdd/SET_ACTIVE_TAB', 0)
    },

    next () {
      this.$store.commit('proyek/poAdd/SET_ACTIVE_TAB', 2)
    }
  }
}
</script>
